import React from "react";
import { Header } from "../Header/Header";

export const Human = () => {
  return (
    <>
      <Header />
      <div className="content-header">sometimes i human</div>
      <div className="content-p">
        In my personal life, I enjoy listening to and playing music, gardening,
        cooking from scratch, baking bread, cycling, woodworking projects, and
        am always trying to learn new skills. I read, I write, sometimes I code
        at night. I'm a firm believer that variety is the spice of life.
      </div>
    </>
  );
};

import React from "react";
import { Header } from "../Header/Header";

export const Professional = () => {
  return (
    <>
      <Header />
      <div className="content-header">accomplishments</div>

      <div className="content-p">
        My best work connects business goals, data, and technology to create and
        extract value. Some of my professional highlights include:
      </div>
      <div className="content-p">
        <ul>
          <li>
            Pioneering the cannabis industry's earliest integrations with
            seed-to-sale platforms Metrc, BioTrack, and LeafLogix. Launching the
            cannabis industry's first integration with the best in class
            accounting platform, Sage IntAcct.
          </li>
          <li>
            Joining ShopRunner's product leadership group under a newly formed
            executive team, and growing the organization to over 120 net new
            employees. Launching the first integrations with Auth0, Apple Pay
            and Chase Pay. ShopRunner was ultimately acquired by FedEx.
          </li>
          <li>
            Partnering with international business executives and product
            leaders to launch Signal's Live Data Management Platform, used by
            organizations like Yahoo! Japan and Fortune 500 companies around the
            world. Signal was ultimately acquired by TransUnion.
          </li>
          <li>
            Spearheading the first ad tech integrations for Centro's Basis
            platform, saving ad agencies millions of dollars in operational
            inefficiencies and fostering an open adtech ecosystem.
          </li>
          <li>
            Building and launching my first company at the age of 23, a
            profitable apartment listing startup with national coverage and over
            16k listings.
          </li>
        </ul>
      </div>

      <div className="content-header">product management</div>
      <div className="content-p">
        I'm a product leader with experience across all stages of the product
        development lifecycle. I have a strong background leading product
        strategy // ideation // definition // execution in coordination with
        executive leadership and software engineering teams to deliver both
        technical products and customer-facing experiences. I've launched 0-1
        product experiences and taken existing products to scalable self-service
        experiences.
      </div>

      <div className="content-header">software engineering</div>
      <div className="content-p">
        As a software engineer, I enjoy building across the stack. I've crafted
        intuitive, responsive user experiences. I love building reusable,
        stateful frontend component architectures and have built reliable
        backends on scalable, performant architecture.
      </div>
      <div className="content-p">
        I love learning new technologies and have hands on experience with the
        following languages, frameworks, and tools:
      </div>

      <div className="content-p">
        <ul>
          <li>
            Kotlin // Spring Boot, Python // Django, Elixir // Phoenix // Ecto,
            Java, PHP
          </li>
          <li>
            React, TypeScript, JavaScript, Bootstrap, Material UI, Tachyons,
            HTML // CSS // SCSS, yarn // npm
          </li>
          <li>PostgreSQL, MySQL, SQL, Liquibase, Flyway</li>
          <li>REST, JSON, GraphQL // Apollo Client, XML</li>
          <li>
            Docker, Kubernetes, Google Cloud Platform, Terraform, AWS S3 // ECS
            // SQS // Lambdas // SES
          </li>
          <li>Snowflake, Postico, DBeaver, Looker, DataDog</li>
          <li>Github, Visual Studio Code, IntelliJ, PyCharm</li>
        </ul>
      </div>

      <div className="content-header">leadership</div>
      <div className="content-p">
        I'm a strong leader who can build and energize teams to deliver on clear
        business goals. I lead with trust and manage with respect. I've been
        called empathetic, and pride myself on being a reasonable human-centric
        leader. I've conducted hundreds of interviews while recruiting teams of
        many disciplines, ensured a successful and welcoming onboarding process,
        and have maintained high retention rates by providing clear career paths
        and growth opportunities for my teams.
      </div>

      <div className="content-header">business</div>
      <div className="content-p">
        My business background is in adtech, martech, and customer data
        management. I'm experienced with identity products at scale including
        authentication, audience segmentation, 1:1 personalization, and
        experience optimization. I've spent time optimizing checkout funnels and
        payment products, and have some war stories about managing ecommerce
        fraud risk with Fortune 500 companies. Over the past few years, I've
        helped cannabis startups scale their businesses by launching
        self-service feature sets including seed-to-sale and accounting
        integrations, and have personally built out multiple comprehensive B2B
        marketplace experiences.
      </div>
    </>
  );
};

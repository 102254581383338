import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

export const SayHello = () => {
  return (
    <>
      <div className="content-header routes">
        say hello&nbsp;
        <a
          className="link-icon"
          href="mailto:andrew@andrewjgraf.com"
          title="email"
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </a>
        &nbsp;
        <a
          className="link-icon"
          href="https://www.linkedin.com/in/andrewgraf/"
          target="_blank"
          title="linked in"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedin} />
        </a>
      </div>
    </>
  );
};

import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './components/Routes';
import { SayHello } from './components/Header/SayHello';

import './styles/App.css';

export const AppContainer = () => {

    return (
        <>
            <Router>
                <Routes />
            </Router>
            <SayHello />
        </>
    );
}

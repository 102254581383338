import React from "react";
import { Links } from "./Links";

export const Header = ({ headerText }) => {
  return (
    <div>
      <div className="intro">
        <span className="slashes">{`//`}</span>
        <span>&nbsp;{headerText ?? `andrew graf`}</span>
      </div>

      <Links />
    </div>
  );
};

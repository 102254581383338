import React from 'react';

export const Writing = () => {

    return (
        <>
            <div className="content-p">
                Sometimes I write stories. This one is called Mystic Sour, which was published in the Story Week Reader in 2008 and again in a zine called Unnamed in 2009.
            </div>
            
            <div className="content-header">mystic sour</div>
            <div className="content-story">
                <span>I see she come in one night. Slice the filtering out weeknight crowd in half. She got a crick in her neck an a chip on her shoulder. She make her way toward me, open her mouth. “Gimme a sammich, kid.”</span>
                <span>She got a wrinkly voice. “I wanna sammich, kid,” she say. I look down, she at me, an I don look up. Hector say she spell you if you let her. Say she evil.</span>
                <span>“Whakind lady?” I ask.</span>
                <span>“Don matter,” she snap back.</span>
                <span>When you make the sanwiches, the lead heads in line don even notice you. An if they do, boss scowl at you, take out his anger on you. Wrong place at the wrong time. Sure sign a somethin. </span>
                <span>“Look Princess,” I say. “Gotta know whatchya want or I cant make ya nothin.”</span>
                <span>“Jus make it better than las time. OO-EE-GODDAMN!” she crow. “Thatsa bad sammich.”</span>
                <span>I be pissed if I were her, too. Ugly lady. Wrinkly face an ashy, too.</span>
                <span>Hector say she take his bus home. Say she go further, sit longer. Husband left her two years back, couldnt take it no more. Musta drove him to wits end. </span>
                <span>I say, “Princess, don know whatcha got las time. I didn make it.” </span>
                <span>“Sonnyboy,” she growl. “Make me somethin special. Momma hungry. An don mess it up. Wouldn wanna make you sleep in the doghouse tonight.” She check herself in the window, mutter real low like, “Mmm-Momma.” The sanwich bake an she talk her crazy fool mouth off. </span>
                <span>“When you breakin outta here, baby?” she ask. “Oo, do I needsa man. See you aint cleanin no floors today,” she say. “Getta promotion, didjya?” </span>
                <span>“Sweetheart,” I say, “You mus not know what I know bout this place. The onion slime, rotting tomato.” I hold up a scraggly slice, the juice dripping onto my yellow cutting board. Yellow from blood cuts and dirty knives. “Cant get no promotion, already know it all.” </span>
                <span>She don phase. I see Boss, his pristine polo.</span>
                <span>“Boss,” I say an point at her. “Get her gone. She causin trouble.” He look at me, open his mouth, raise his hand an mumble, “Whatever, man.” </span>
                <span>Hes a six to two, a twelve to eight, not a nine to five. The difference? Do it an you find out. </span>
                <span>“Sorry lady,” I say, “Bosses orders. You gotta jet, but I let you getchyo sammich.” </span>
                <span>She pitch a fit. “Whatdya mean jet? Canna lady sedown to eata sammich?” Her eyes open wide. </span>
                <span>“Ainnobody get a sammich if I aint,” she taunt, raise her arms up like she somethin special, then pound her fists down on the counter. </span>
                <span>I look to Boss an say, “Boss, I quit. Cant handle the crazies no more.” </span>
                <span>“Ain no crazy, baby,” she plead.</span>
                <span>“Lady, get,” Boss tell her, strong on the get. </span>
                <span>“I ain no crazy!” she holler. </span>
                <span>“Done, Im done,” I say to him. The lead heads in line look around, don know what to do. Never do much now, why start?</span>
                <span>Raphael, his skinny waist an mascara, he prance over. “Lady, Daddy gun fix you up.” He snarls, then winks at her.</span>
                <span>“Nu-uh, no you didn, Boy,” she holler.</span>
                <span>Boss throw his weight around. The lady laugh, kiss her tangled necklaces, know she won a prize for stickin it to some man. </span>
                <span>Boss, arms crossed, wonder if he gone yell at me or let me be. He call his boss, stammer on the phone, “Yes, Sir. I’ll try my best, Sir. Yes, Sir.” He look at me, clench his jaw, shift his head. </span>
                <span>“Lady, thought I toldjya ta leave,” I say. “Gotchyo sanwich. Now get.”</span>
            </div>
        </>
    );
}
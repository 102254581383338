import React from "react";
import { Writing } from "./Writing";
import { Header } from "../Header/Header";

export const Wrapper = () => {
  return (
    <>
      <Header />
      <Writing />
    </>
  );
};

import React from "react";

export const Links = () => {
  return (
    <div className="routes">
      <a href="/">about</a>
      &nbsp;//&nbsp;
      <a href="/professional">professional</a>
      &nbsp;//&nbsp;
      <a href="/writing">writer</a>
      &nbsp;//&nbsp;
      <a href="/human">human</a>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { Links } from "./Links";

export const HomeHeader = () => {
  const [visible, setVisible] = useState(true);
  const [headerText, setHeaderText] = useState("oh, hello");

  useEffect(() => {
    setVisible(false);
    setTimeout(introduceYourself, 3000);
  }, []);

  const introduceYourself = () => {
    setHeaderText(`i'm andrew`);
    setVisible(true);
  };

  return (
    <div>
      <div className="intro">
        <span className="slashes">{`//`}</span>
        <span className={visible ? "fadeIn" : "fadeOut"}>
          &nbsp;{headerText}
        </span>
      </div>

      <Links />
    </div>
  );
};

import React from "react";
import { HomeHeader } from "../Header/HomeHeader";
import { About } from "../Home/About";

export const Home = () => {
  return (
    <>
      <HomeHeader />
      <About />
    </>
  );
};

import React from "react";

export const About = () => {
  return (
    <>
      <div className="content-p">
        I’m a technologist with 10+ years of experience building and launching
        software products that scale to millions of customers and solve
        meaningful business problems. I'm no stranger to 100%+ YoY growth, and
        have spent my career building technology teams that are autonomous,
        empowered, and successful.
      </div>

      <div className="content-p">
        I love building teams and products. My specialties are defining product
        strategy // roadmaps, scaling teams // hiring strategy // capacity
        planning, and building scalable product experiences // integrations.
        Reach out if there are ideas I can help you bring to life.
      </div>

      <div className="content-p">
        Keep havin' a great day!
        <span
          style={{ color: "#060", fontSize: "1.2rem", marginLeft: ".5rem" }}
        >
          🤙
        </span>
      </div>
    </>
  );
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "./Home/Home";
import { Wrapper } from "./Writing/Wrapper";
import { Professional } from "./Professional/Professional";
import { Human } from "./Human/Human";

export const Routes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/professional" component={Professional} />
      <Route exact path="/human" component={Human} />
      <Route exact path="/writing" component={Wrapper} />
    </Switch>
  );
};
